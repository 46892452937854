import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Pagination, TextField, Divider } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // Icona per il tasto di copia
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import HomeIcon from '@mui/icons-material/Home';
import { db } from './Dexie'; // Importa Dexie
import { getTranslations, modifyTranslation, addTranslation, deleteTranslation, newStringTranslation, getDescriptionTranslate } from '../services/translationService'; // Servizi API

function Translation() {
  const [translations, setTranslations] = useState([]);
  const [editingTranslation, setEditingTranslation] = useState(null); // Stato per la traduzione in modifica
  const [currentPage, setCurrentPage] = useState(1); // Stato per la pagina corrente
  const [itemsPerPage] = useState(10); // Numero di traduzioni per pagina
  const [totalPages, setTotalPages] = useState(1); // Numero totale di pagine
  const [searchQuery, setSearchQuery] = useState(''); // Stato per la ricerca
  const [showAddForm, setShowAddForm] = useState(false); // Stato per mostrare/nascondere il form di aggiunta
  const [newTranslation, setNewTranslation] = useState({ string: '', language: '', description: '' }); // Stato per la nuova traduzione
  const navigate = useNavigate();

  // Funzione per caricare le traduzioni da Dexie in base alla pagina e alla query di ricerca
  const loadTranslationsFromDexie = useCallback(async (page, searchQuery = '') => {
    const offset = (page - 1) * itemsPerPage;
    let results;

    if (searchQuery) {
      results = await db.strings
        .filter(item => 
          item.string.includes(searchQuery) || 
          item.description.includes(searchQuery)
        )
        .offset(offset)
        .limit(itemsPerPage)
        .toArray();
    } else {
      results = await db.strings.offset(offset).limit(itemsPerPage).toArray();
    }

    const count = await db.strings.count();
    setTranslations(results);
    setTotalPages(Math.ceil(count / itemsPerPage));
  }, [itemsPerPage]);

  const reloadTranslation = async () => {
    await getTranslations();
    loadTranslationsFromDexie(1);
  }

  useEffect(() => {
    const ciao = async () => {
      let lastUpdateDateTime = await db
        .genericTable
        .where({
          table: 'genericData', 
          field: 'lastUpdateDateTime'})
        .toArray();
      let diffDataTime = null;
      if(lastUpdateDateTime.length > 0) {
        lastUpdateDateTime = new Date(lastUpdateDateTime[0].value);
        let nowDateTime = new Date();
        diffDataTime = new Date(nowDateTime.getTime() - lastUpdateDateTime.getTime());
      } else
        lastUpdateDateTime = false;

      if(!lastUpdateDateTime || (diffDataTime.getUTCDate() - 1) >= 1)
        await getTranslations();

      loadTranslationsFromDexie(1);
    }

    ciao();
  }, [loadTranslationsFromDexie]);

  // Chiamata API iniziale e salvataggio su Dexie
  useEffect(() => {
    const fetchAndSaveTranslations = async () => {
      try {
        loadTranslationsFromDexie(currentPage);
      } catch (error) {
        console.error("Errore nel caricamento delle traduzioni dall'API:", error);
      }
    };

    fetchAndSaveTranslations();
  }, [currentPage, loadTranslationsFromDexie]);

  // Funzione per modificare una traduzione e aggiornare Dexie
  const handleSaveEdit = async (originalTranslation, updatedTranslation) => {
    const { string, language, description } = updatedTranslation;

    const isModified = (
      originalTranslation.string !== string ||
      originalTranslation.language !== language ||
      originalTranslation.description !== description
    );

    if (isModified) {
      try {
        await modifyTranslation(originalTranslation, updatedTranslation);
        await db.strings
          .where({string: string, language: language})
          .modify(updatedTranslation);

        setTranslations(translations.map(translation =>
          translation.string === originalTranslation.string &&
          translation.language === originalTranslation.language
            ? updatedTranslation : translation
        ));

        alert('Traduzione modificata!');
      } catch (error) {
        console.error("Errore nella modifica della traduzione:", error);
      }
    }

    setEditingTranslation(null);
  };

  // Funzione per cancellare una traduzione e aggiornare Dexie
  const handleDelete = async (id) => {
    try {
      await deleteTranslation({string: id.string, language: id.language});
      await db.strings
        .where({string: id.string, language: id.language})
        .delete();
      setTranslations(await db.strings.toArray());
    } catch (error) {
      console.error("Errore nella cancellazione della traduzione:", error);
    }
  };

  // Funzione per copiare una traduzione
  const handleCopy = async (translation) => {
    try {
      setNewTranslation({ string: translation.string, language: '', description: '' });
      setShowAddForm(true);
      await navigator.clipboard.writeText(translation.description);
    } catch (error) {
      console.error("Errore nella copia della traduzione:", error);
    }
  };

  // Funzione per aggiungere una nuova traduzione
  const handleAddTranslation = async () => {
    let { string, language, description } = newTranslation;

    try {
      string = string.toString();
      await addTranslation({ string, language, description });
      await db.strings.put({ string, language, description });

      setTranslations([{ string, language, description }, ...translations]);

      setNewTranslation({ string: '', language: '', description: '' });
      setShowAddForm(false);
    } catch (error) {
      console.error("Errore nell'aggiunta della traduzione:", error);
    }
  };

  // Funzione per gestire la cancellazione del form di aggiunta
  const handleCancelAdd = () => {
    setNewTranslation({ string: '', language: '', description: '' });
    setShowAddForm(false);
  };

  // Funzione per cambiare pagina
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    loadTranslationsFromDexie(value, searchQuery);
  };

  // Funzione per gestire la ricerca
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    loadTranslationsFromDexie(currentPage, event.target.value);
  };

  const handleToggleAddForm = async () => {
    if(!showAddForm) {
      let result = await newStringTranslation();
      setNewTranslation({ string: result.details, language: '', description: '' });
    }

    setShowAddForm(!showAddForm);
  };

  const handleAddNewTranslationChangeLanguage = async (newString) => {
    setNewTranslation(newString); // { ...newTranslation, language: e.target.value }
    if(newString.language.length === 2) {
      const engString = await db.strings.where({string: newString.string, language: 'en'}).toArray();
      if(engString.length <= 0)
        return false;
      const response = await getDescriptionTranslate(newString.language, engString[0].description);
      setNewTranslation({ ...newTranslation, language: newString.language, description: response });
    }
  };

  return (
    <div>
      <h1>
        Gestione delle Traduzioni
        <IconButton onClick={() => navigate("/")}>
          <HomeIcon color='primary' />
        </IconButton>
      </h1>

      <TextField 
        label="Cerca per numero string o descrizione"
        variant="standard"
        fullWidth
        value={searchQuery}
        onChange={handleSearch}
        className='custom-input'
        style={{ marginBottom: '20px' }}
      />

      <Button 
        onClick={() => handleToggleAddForm()}
        className='m-2'
        variant="outlined" 
        color="primary" 
      >
        Aggiungi Traduzione
      </Button>

      <Button 
        onClick={() => reloadTranslation()}
        className='m-2'
        variant="outlined" 
        color="primary" 
      >
        Aggiorna traduzioni
      </Button>

      {showAddForm && (
        <div style={{ marginBottom: '20px' }}>
          <TextField
            label="String"
            variant="standard"
            className='custom-input'
            fullWidth
            value={newTranslation.string}
            onChange={e => setNewTranslation({ ...newTranslation, string: e.target.value })}
            style={{ marginBottom: '10px' }}
          />
          <TextField
            label="Lingua"
            variant="standard"
            className='custom-input'
            fullWidth
            value={newTranslation.language}
            onChange={e => handleAddNewTranslationChangeLanguage({ ...newTranslation, language: e.target.value })}
            style={{ marginBottom: '10px' }}
          />
          <TextField
            label="Descrizione"
            variant="standard"
            className='custom-input'
            fullWidth
            multiline
            value={newTranslation.description}
            onChange={e => setNewTranslation({ ...newTranslation, description: e.target.value })}
            style={{ marginBottom: '20px' }}
          />
          <Button 
            onClick={handleAddTranslation} 
            color="primary" 
            variant="contained"
          >
            Salva Traduzione
          </Button>
          <Button 
            onClick={handleCancelAdd} 
            color="secondary" 
            variant="outlined" 
            style={{ marginLeft: '10px' }}
          >
            Annulla
          </Button>
        </div>
      )}

      <List>
        {translations.map((translation) => (
          <React.Fragment key={`${translation.string}-${translation.language}`}>
            <ListItem>
              <ListItemText
                className='text-truncate'
                primary={`String: ${translation.string}`}
                secondary={`Lingua: ${translation.language} | Descrizione: ${translation.description}`}
              />
              <ListItemSecondaryAction>
                <IconButton onClick={() => setEditingTranslation(translation)}>
                  <ModeEditIcon color="primary" />
                </IconButton>
                <IconButton onClick={() => handleDelete(translation)}>
                  <DeleteIcon color="secondary" />
                </IconButton>
                <IconButton onClick={() => handleCopy(translation)}>
                  <ContentCopyIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            {editingTranslation && editingTranslation.string === translation.string && (
              <ListItem>
                <div style={{ width: '100%' }}>
                  <TextField
                    label="String"
                    variant="outlined"
                    fullWidth
                    value={editingTranslation.string}
                    onChange={e => setEditingTranslation(prev => ({ ...prev, string: e.target.value }))}
                    style={{ marginBottom: '10px' }}
                  />
                  <TextField
                    label="Lingua"
                    variant="outlined"
                    fullWidth
                    value={editingTranslation.language}
                    onChange={e => setEditingTranslation(prev => ({ ...prev, language: e.target.value }))}
                    style={{ marginBottom: '10px' }}
                  />
                  <TextField
                    label="Descrizione"
                    variant="outlined"
                    fullWidth
                    multiline
                    value={editingTranslation.description}
                    onChange={e => setEditingTranslation(prev => ({ ...prev, description: e.target.value }))}
                    style={{ marginBottom: '20px' }}
                  />
                  <Button 
                    onClick={() => handleSaveEdit(translation, editingTranslation)} 
                    color="primary" 
                    variant="contained"
                  >
                    Salva Modifiche
                  </Button>
                  <Button 
                    onClick={() => setEditingTranslation(null)} 
                    color="secondary" 
                    variant="outlined" 
                    style={{ marginLeft: '10px' }}
                  >
                    Annulla
                  </Button>
                </div>
              </ListItem>
            )}
          </React.Fragment>
        ))}
      </List>

      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
      />
    </div>
  );
}

export default Translation;
