import axios from 'axios';
import { db } from '../components/Dexie'; // Importa Dexie

const API_URL = 'https://deadapi.techysumo.it/backoffice/';
const API_AUT = 'TEST_KEY';
const API_HEADER = {
  Authorization: 'Bearer '+API_AUT // Inserisci la tua chiave API
}

// Ottenere tutte le traduzioni
export const getTranslations = async () => {

  const response = await axios.post(`${API_URL}getStrings`, {
    // Parametri necessari per l'API, se richiesti
  }, { headers: API_HEADER });
  const apiTranslations = response.data;

  if(!apiTranslations || !apiTranslations.result)
    return false;

  await db.strings.bulkPut(apiTranslations.details);
  const lastTimeUpdate = new Date();
  db.genericTable
    .put({table: 'genericData', field: 'lastUpdateDateTime', value: lastTimeUpdate.getTime()})
    .then(()=>console.log("datetime aggiornato"))
    .catch(()=>console.log("datetime non aggiornato"));
  return apiTranslations.details;

};

// Cancellare una traduzione
export const deleteTranslation = async (id) => {
  
  const data = {};
  data.table = 'exkart_strings';
  data.filter = [
    {field: "string", value: id.string},
    {field: "language", value: id.language}
  ];
  const result = await axios.post(`${API_URL}deleteDatabase`, data, { headers: API_HEADER });

  return result;

};

// Modificare una traduzione
export const modifyTranslation = async (original, update) => {

  let data = {
    original: original, 
    update: update
  };

  return axios.post(`${API_URL}modifyTranslation`, data, { headers: API_HEADER });

};

// Aggiungere una traduzione
export const addTranslation = async (newDescription) => {
  return axios.post(`${API_URL}addTranslation`, newDescription, { headers: API_HEADER });
};

export const newStringTranslation = async () => {
  const data = {};
  data.table = 'exkart_strings';
  const response = await axios.post(API_URL + "getRandomNumber", data, {headers: API_HEADER});
  return response.data;
};

export const getDescriptionTranslate = async (toLanguage, description, fromLanguage = false) => {

  let data = {};
  data.toLanguage = toLanguage;
  data.description = description;
  if(fromLanguage)
    data.fromLanguage = fromLanguage;

  const response = await axios.post(API_URL + "getTranslate", data, {headers: API_HEADER});
  return response.data;

};